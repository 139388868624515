import { Presenter } from '~/framework/Presenter';
import { User } from '~/state/mainAppState';
import { CompanyInfo } from '~/types/CompanyInfo';

export const fallbackInfo: PresentableCompayStyle = {
  primaryColor: '#0C48BC',
  hoverColor: '#2b7fff',
  activeColor: '#0054d4',
  gradientStartColor: '#0C48BC',
  gradientEndColor: '#0065ff',
  name: '',
};

export const presentCompanyInfo = (companyInfo?: CompanyInfo): PresentableCompayStyle => {
  return {
    name: companyInfo?.name || '',
    logo: companyInfo?.logo ? '/api' + companyInfo?.logo : '',
    cover: companyInfo?.coverUrl ? '/api' + companyInfo?.coverUrl : '',
    primaryColor: companyInfo?.style.primaryColor,
    hoverColor: companyInfo?.style.hoverColor,
    activeColor: companyInfo?.style.activeColor,
    gradientStartColor: companyInfo?.style.gradientStartColor,
    gradientEndColor: companyInfo?.style.gradientEndColor,
    email: companyInfo?.email,
    phoneNumber: companyInfo?.phoneNumber,
    facebook: companyInfo?.facebook,
    instagram: companyInfo?.instagram,
    linkedIn: companyInfo?.linkedIn,
    twitter: companyInfo?.twitter,
    tikTok: companyInfo?.tikTok,
    youtube: companyInfo?.youtube,
    metadataTitle: companyInfo?.metadataTitle,
    metadataDescription: companyInfo?.metadataDescription,
    metadataThemeColor: companyInfo?.metadataThemeColor,
    metadataTitleTemplate: companyInfo?.metadataTitleTemplate,
    isCompanyInfoLoaded: !!companyInfo,
    themeEmptyIcon: companyInfo?.themeEmptyIcon,
    socialMedia:
      !!companyInfo?.facebook ||
      !!companyInfo?.instagram ||
      !!companyInfo?.linkedIn ||
      !!companyInfo?.twitter ||
      !!companyInfo?.tikTok ||
      !!companyInfo?.youtube,
    iconUrl: companyInfo?.iconUrl,
    complianceIconRealtor: companyInfo?.complianceIcons?.realtor,
    complianceIconMls: companyInfo?.complianceIcons?.mls,
    equalHouseing: companyInfo?.equalHouseing,
    canReceiveRentalApplications: companyInfo?.canReceiveRentalApplications,
    translationLanguages: companyInfo?.translationLanguages,
    language: companyInfo?.language,
    currentLanguage: companyInfo?.currentLanguage,
  };
};

export interface PresentableCompayStyle {
  name: string;
  logo?: string;
  cover?: string;
  primaryColor?: string;
  hoverColor?: string;
  activeColor?: string;
  gradientStartColor?: string;
  gradientEndColor?: string;
  email?: string;
  phoneNumber?: string;
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  twitter?: string;
  tikTok?: string;
  youtube?: string;
  metadataTitle?: string;
  metadataDescription?: string;
  metadataThemeColor?: string;
  metadataTitleTemplate?: string;
  iconUrl?: string;
  isCompanyInfoLoaded?: boolean;
  themeEmptyIcon?: string;
  socialMedia?: boolean;
  complianceIconRealtor?: boolean;
  complianceIconMls?: boolean;
  equalHouseing?: boolean;
  canReceiveRentalApplications?: boolean;
  translationLanguages?: string[];
  language?: string;
  currentLanguage?: string;
}

export class CompanyStylePresenter extends Presenter<PresentableCompayStyle> {
  protected createModel = (state: User): PresentableCompayStyle => {
    return presentCompanyInfo(state.companyInfo);
  };
}
